import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Hartford Car Accident Lawyer",
    heroH1: "Hartford Car Accident Lawyer",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Are you looking for a Hartford car accident attorney?</h2>
            <p className="mb-8">
              Then your search is over. You need Frank C. Bartlett Jr. – the
              Hartford area car accident lawyer whose valuable experience helps
              you get what you deserve and not just what the insurance company
              wants to give you. Attorney Bartlett has clerked at the appellate
              courts, he has represented insurance companies as defendants at
              trial, and he has successfully represented hundreds of clients
              injured in a serious car, truck, and motorcycle accidents over the
              past 14 years. Since opening, Bartlett Legal Group, LLC has chosen
              to only represent plaintiffs or the injured. Our motto is “Giving
              Victims a Voice”.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../../images/top-attorney-car-accident.png"
                formats={["auto", "webp", "avif"]}
                alt="Rated 10 Top Car Accident Attorney"
              />
            </div>
          </div>

          <div className="mb-16">
            <h2>
              If you or a family member were hurt in a car accident, you need a
              Hartford car accident lawyer.
            </h2>
            <p className="mb-8">
              We are here to help you and your family through the legal process
              and to help maximize your compensation for your injuries. It’s not
              only our job to know the legal aspects of the case but it’s also
              our job to help reduce your anxiety and fear of the legal process.
              We care about you and your family and our team wants to help.
            </p>
            <p className="mb-8">
              <Link to="/car-accident-lawyer/">
                Click here for the answers to important questions after a New
                Haven car accident. This information may change the outcome of
                your case.
              </Link>
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>About Bartlett Legal Group, LLC</h2>
            <p className="mb-8">
              <Link to="/ct-wrongful-death/">
                We are your Connecticut personal injury firm with a focus on
                wrongful death cases
              </Link>
              , serious accident cases, and sexual abuse cases. We are a
              litigation firm which means if you choose our firm, you will have
              the same attorney from the beginning to the end. Your trial
              attorney will know you, your family and the details of your case.
              All cases are treated as if they may eventually go to trial. By
              taking this approach we are better prepared if your case goes to
              trial and we have a better chance of getting you a fair
              settlement. Never assume you may not have a case without first
              talking to an accident attorney at Bartlett Legal Group, LLC. You
              have nothing to lose and everything to gain by calling our
              attorney now.
            </p>
          </div>
          <div className="mb-16">
            <h2>
              Hartford County Accident Information for the past five years
            </h2>
            <p className="mb-8">
              Below are crash statistics compiled from November 2003 to November
              2018 for Hartford County. Hartford county includes the towns of
              Andover, Avon, Berlin, Bloomfield, Bolton, Canton, Columbia,
              Coventry, East Granby, East Hartford, East Windsor, Ellington,
              Enfield, Farmington, Glastonbury, Granby, Hartford, Hebron,
              Manchester, Mansfield, Marlborough, New Britain, Newington,
              Plainville, Rocky Hill, Simsbury, Somers, Southington, South
              Windsor, Stafford, Suffield, Tolland, Vernon, West Hartford,
              Wethersfield, Willington, Windsor, and Windsor Locks. We represent
              clients in all of these towns.
            </p>
            <p className="mb-8">There were:</p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>107,789 crash records</li>
              <li>204,692 vehicles</li>
              <li>involving 265,239 people</li>
              <li>and 14,266 trailers</li>
            </ul>

            <h3>City of Hartford accident statistics</h3>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>22,896 crash records</li>
              <li>45,738 vehicle crash records</li>
              <li>involving 61,528 people</li>
              <li>and 4,356 involving trailers</li>
            </ul>
          </div>
          <div className="mb-16">
            <h2>Were you involved in a motorcycle accident in Hartford?</h2>
            <p className="mb-8">
              We are riders and know first hand the dangers of riding. Our
              Hartford motorcycle accident attorney can help you through this
              difficult time.
            </p>
            <p className="mb-8">
              <Link to="/ct-wrongful-death/motorcycle-accidents/">
                Visit our motorcycle accident page by clicking here.
              </Link>
            </p>
          </div>
          <div className="mb-16">
            <h2>
              Were you in an accident with a trailer truck? Call our Hartford
              truck accident lawyer now.
            </h2>
            <p className="mb-8">
              This is a complex type of motor vehicle accident case. There are
              many contributing factors to truck accidents:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>Rollovers</li>
              <li>Drunk, intoxicated, or DUI truck drivers</li>
              <li>Overloaded vehicles</li>
              <li>Sleep deprived drivers</li>
              <li>Distracted drivers such as texting while driving</li>
              <li>Unsafe trucks</li>
              <li>
                Trucks that have not corrected issues found during inspections
              </li>
            </ul>
            <p className="mb-8">
              One of Attorney Bartlett’s first cases fourteen years ago involved
              a tractor-trailer accident. Because of Attorney Bartlett’s
              hands-on experience with total restoration of vehicles as a hobby,
              he was able to ask technical questions about the construction and
              maintenance of the braking system on the truck. These questions
              changed the outcome of the case. You not only need someone that
              knows the law, but you also need someone that can challenge the
              other side’s experts.
            </p>
          </div>
          <div className="mb-16">
            <h2>Hartford Area Superior Court Location</h2>

            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="500"
                src="../../images/hartford-courthouse-300x158.png"
                formats={["auto", "webp", "avif"]}
                alt="Photo of the Hartford Area Superior Court"
              />
            </div>
            <p className="mb-8 text-center">
              <strong>920 Broad St. Hartford CT</strong>
            </p>
            <p className="mb-8">
              If my case doesn’t settle and we have to take it to court, where
              is the court located? Because of our experience, reputation, and
              because we prepare every case as if it will go to trial, the
              insurance companies often offer us a fair settlement. It is your
              ultimate decision whether you accept a potential settlement offer.
              We will discuss your options along with upside potentials and
              downside risks associated with going to court. If you decide you
              want to go to court, most Hartford area accident cases will be
              heard at the Courthouse at 95 Washington St. Hartford CT.{" "}
              <a
                href="https://jud.ct.gov/directory/directory/directions/26.htm"
                target="_blank"
                rel="noreferrer"
              >
                For additional information about parking, wheelchair
                accessibility, phone numbers, and directions to the Hartford
                Superior court please click here
              </a>
            </p>
          </div>
          <div className="mb-16">
            <h2>Useful local Hartford information and links.</h2>
            <p className="mb-8">
              Being on time for the court is critical. Know your weather the
              night before your trial so that you can plan accordingly.{" "}
              <a
                href="https://www.accuweather.com/en/us/hartford-ct/06106/weather-forecast/327356"
                target="_blank"
                rel="noreferrer"
              >
                Here’s a link to the Hartford Area weather.
              </a>
            </p>
            <p className="mb-8">Traffic can also play a role. <a href="https://www.mapquest.com/search/result?query=95%20Washington%20St.%20Hartford%20CT&boundingBox=46.649436163350245,-120.9814453125,29.305561325527698,-75.0146484375&page=0&index=0" target="_blank" rel="noreferrer">Here’s a map to the courthouse with traffic conditions.</a> Just click directions and enter your starting location.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
